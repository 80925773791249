import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'; 

class DeleteAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      deleteAccount: false,
      deleteWithData: false,
      emailError: false,
      passwordError: false,
      showPassword: false, 
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDeleteAccountChange = () => {
    this.setState((prevState) => ({ deleteAccount: !prevState.deleteAccount }));
  };

  handleDeleteWithDataChange = () => {
    this.setState((prevState) => ({ deleteWithData: !prevState.deleteWithData }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const { email, password, deleteAccount, deleteWithData } = this.state;

    let hasError = false;

    if (!emailRegex.test(email)) {
      this.setState({ emailError: true });
      console.error("Invalid email address");
      hasError = true;
    } else {
      this.setState({ emailError: false });
    }

    if (password.length < 8) {
      this.setState({ passwordError: true });
      console.error("Password must be at least 8 characters");
      hasError = true;
    } else {
      this.setState({ passwordError: false });
    }

    if (hasError) {
      return;
    }

    try {
      const response = await axios.delete(`https://api.menupro.tn/users/delete/${email}`, {
        params: {
          password: password,
        },
      });
      console.log('Account deleted:', response.data);
      this.setState({ email: '', password: '', errorMessage: '' });
    } catch (error) {
      console.error('Error deleting account:', error);
      if (error.response && error.response.data) {
        this.setState({ errorMessage: error.response.data.message });
      } else {
        this.setState({ errorMessage: 'An error occurred while deleting the account.' });
      }
      swal("Oops", "Le compte est introuvable", "error", { button: "Retenter!" });
    }
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  render() {
    const { email, password, emailError, passwordError, deleteAccount, deleteWithData, showPassword } = this.state;
    return (
      <div>
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        <div id="page" className="page">
          <section id="contacts-2" className="bg_whitesmoke hero-offset-nav pb-50 contacts-section division">
            <div className="mt-50 container">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    <h2 className="h2-md">Supprimer le Compte Utilisateur</h2>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                  <div className="form-holder">
                    <form name="contactform" className="row contact-form" onSubmit={this.handleSubmit}>
                      <div id="input-email" className="col-md-12">
                        <p className="p-lg">Votre adresse e-mail :</p>
                        <span>Veuillez vérifier attentivement votre adresse e-mail pour sa précision</span>
                        <input
                          type="email"
                          name="email"
                          className="form-control email"
                          placeholder="Adresse e-mail*"
                          value={email}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div id="input-password" className="col-md-12">
                        <p className="p-lg">Votre mot de passe :</p>
                        <span>Veuillez entrer votre mot de passe :</span>
                        <div className="password-input">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control password"
                            placeholder="Votre mot de passe*"
                            value={password}
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={deleteAccount}
                            onChange={this.handleDeleteAccountChange}
                          />
                          <label className="ml-10 mr-10 checkbox-label">Supprimer le compte</label>
                          <input
                            type="checkbox"
                            checked={deleteWithData}
                            onChange={this.handleDeleteWithDataChange}
                          />
                          <label className="ml-10 mr-10 checkbox-label">Supprimer le compte avec les données</label>
                        </div>
                      </div>
                      <div className="col-md-12 mt-15 form-btn text-right">
                        <button type="submit" className="btn btn-skyblue tra-skyblue-hover submit">Enregistrer</button>
                      </div>
                      <div className="col-lg-12 contact-form-msg">
                        <span className="loading" />
                      </div>
                    </form>
                    {emailError && <div className="text-danger error-message">L'adresse email est invalide. Veuillez entrer une adresse email valide.</div>}
                    {passwordError && <div className="text-danger error-message">Le mot de passe doit comporter au moins 8 caractères. Veuillez entrer un mot de passe valide.</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg_fixed geometric_overlay" />
          </section>
          <div className="divider-wrapper text-center bg_whitesmoke"><div className="section-divider" /></div>
        </div>
      </div>
    );
  }
}

export default DeleteAccount;
