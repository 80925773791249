import React, { Component } from 'react';
import axios from 'axios';

class JoinUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            lastName: '',
            email: '',
            tel: '',
            message: '',
            cv: null,
            errors: {},
        };
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleFileChange = (e) => {
        const file = e.target.files[0];
        console.log('File details:', {
            name: file.name,
            type: file.type,
            size: file.size
        });
        this.setState({ cv: file });
    };

    handleSubmit = async (e) => {
        e.preventDefault();

        const { name, lastName, email, tel, message, cv } = this.state;

        // Validation des champs
        let errors = {};
        if (!name) errors.name = "Veuillez entrer votre nom.";
        if (!lastName) errors.lastName = "Veuillez entrer votre prénom.";
        if (!email) errors.email = "Veuillez entrer votre adresse e-mail.";
        if (!tel) errors.tel = "Veuillez entrer votre numéro de téléphone.";
        if (!cv) errors.cv = "Veuillez uploader votre CV.";

        if (Object.keys(errors).length > 0) {
            this.setState({ errors });
            return;
        }

        // Envoyer le formulaire
        const formData = new FormData();
        formData.append('name', name);
        formData.append('lastName', lastName);
        formData.append('email', email);
        formData.append('tel', tel);
        formData.append('message', message);
        formData.append('cv', cv);
        console.log('formData', formData);
        try {
            const response = await axios.post('https://api.menupro.tn/join-us', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Form submitted successfully:', response.data);
            // Réinitialiser le formulaire
            this.setState({
                name: '',
                lastName: '',
                email: '',
                tel: '',
                message: '',
                cv: null,
                errors: {},
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };


    render() {
        const { name, lastName, email, tel, message, errors } = this.state;
        return (
            <div>
                {/* PRELOADER SPINNER */}
                <div id="loader-wrapper">
                    <div id="loading">
                        <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
                    </div>
                </div>
                {/* PAGE CONTENT */}
                <div id="page" className="page">
                    {/* CONTACTS-2 */}
                    <section id="contacts-2" className="bg_whitesmoke hero-offset-nav pb-50 contacts-section division">
                        <div className="mt-50 container">
                            {/* SECTION TITLE */}
                            <div className="row">
                                <div className="col-lg-10 offset-lg-1">
                                    <div className="section-title text-center mb-70">
                                        <h2 className="h2-md">Rejoignez-nous</h2>
                                    </div>
                                </div>
                            </div>
                            {/* CONTACT FORM */}
                            <div className="row">
                                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                    <div className="form-holder">
                                        <form name="joinusform" className="row contact-form" onSubmit={this.handleSubmit}>
                                            <div id="input-name" className="col-md-6">
                                                <p className="p-lg">Votre nom :</p>
                                                <span>Veuillez entrer votre vrai nom :</span>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control name"
                                                    placeholder="Votre Nom*"
                                                    value={name}
                                                    onChange={this.handleInputChange}
                                                />
                                                {errors.name && <div className="text-danger error-message">{errors.name}</div>}
                                            </div>
                                            <div id="input-lastname" className="col-md-6">
                                                <p className="p-lg">Votre prénom :</p>
                                                <span>Veuillez entrer votre vrai prénom :</span>
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    className="form-control name"
                                                    placeholder="Votre Prénom*"
                                                    value={lastName}
                                                    onChange={this.handleInputChange}
                                                />
                                                {errors.lastName && <div className="text-danger error-message">{errors.lastName}</div>}
                                            </div>
                                            <div id="input-email" className="col-md-12">
                                                <p className="p-lg">Votre adresse e-mail :</p>
                                                <span>Veuillez vérifier attentivement votre adresse e-mail pour sa précision</span>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    className="form-control email"
                                                    placeholder="Adresse e-mail*"
                                                    value={email}
                                                    onChange={this.handleInputChange}
                                                />
                                                {errors.email && <div className="text-danger error-message">{errors.email}</div>}
                                            </div>
                                            <div id="input-tel" className="col-md-12">
                                                <p className="p-lg">Votre téléphone :</p>
                                                <span>Veuillez vérifier votre numéro de téléphone</span>
                                                <input
                                                    type="text"
                                                    name="tel"
                                                    className="form-control email"
                                                    placeholder="Numéro téléphone*"
                                                    value={tel}
                                                    onChange={this.handleInputChange}
                                                />
                                                {errors.tel && <div className="text-danger error-message">{errors.tel}</div>}
                                            </div>
                                            <div id="input-message" className="col-md-12 input-message">
                                                <p className="p-lg">Description du poste qui vous intéresserait :</p>
                                                <textarea
                                                    className="form-control message"
                                                    name="message"
                                                    rows={4}
                                                    placeholder=""
                                                    value={message}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                            <div id="input-cv" className="col-md-12">
                                                <p className="p-lg">Télécharger votre CV :</p>
                                                <input
                                                    type="file"
                                                    name="cv"
                                                    className="mt-15 form-control"
                                                    onChange={this.handleFileChange}
                                                />
                                                {errors.cv && <div className="text-danger error-message">{errors.cv}</div>}
                                            </div>
                                            <div className="col-md-12 mt-15 form-btn text-right">
                                                <button type="submit" className="btn btn-skyblue tra-skyblue-hover submit">Envoyer la demande</button>
                                            </div>
                                            <div className="col-lg-12 contact-form-msg">
                                                <span className="loading" />
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg_fixed geometric_overlay" />
                    </section>
                    <div className="divider-wrapper text-center bg_whitesmoke"><div className="section-divider" /></div>
                </div>
            </div>
        );
    }
}

export default JoinUs;
