import React, {component} from 'react';
import { Link } from "react-router-dom";

class Footer extends React.Component {
    render(){
        return(
            <div>
<footer id="footer-3" className="footer division">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 offset-lg-1">
							<div className="row">
								
								
								<div className="col-sm-6 col-md-4 col-lg-3">
									<div className="footer-links mb-40">
										<ul className="foo-links clearfix">
											<li><p className="p-md"><Link to="/contact">Contactez nous</Link></p></li>
											<li><p className="p-md"><Link to="/delete">Supprimer compte</Link></p></li>
											<li><p className="p-md"><Link to="/privacy-policy">Politique de confidentialité</Link></p></li>		
											<li><p className="p-md"><Link to="/terms">Conditions générales</Link></p></li>					
										</ul>
									</div>
								</div>
								<div className="col-sm-6 col-md-8 col-lg-3">
									<div className="footer-store-badges text-right mb-40">
										<Link to="https://apps.apple.com/us/app/menupro/id6464469617" className="store">
											<img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
										</Link>
										<Link to="https://play.google.com/store/apps/details?id=com.menupro.app" className="store">
											<img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
										</Link> 
									</div>
								</div>	

							</div>
						</div>
					</div>	 					
					<div className="row">
						<div className="col-lg-10 offset-lg-1">
							<div className="bottom-footer footer-copyright text-center">
								<p>&copy; 2023 Visto. All Rights Reserved</p>
							</div>
						</div>
					</div>
				</div>	   										
			</footer>	
            </div>
        )
    }
}
export default Footer    