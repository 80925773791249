import {useEffect, useState} from 'react';
import {handleDeepLinkRedirection} from './deepLinkRedirection.js';
import {useParams, useLocation} from 'react-router-dom';

const Fallback = () => {
    const {id} = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [checkOS, setCheckOS] = useState(null);

    useEffect(() => {
        const restoId = queryParams.get('restoId');
        const tableIdentifier = queryParams.get('table_identifier');
        const accessToken = queryParams.get('access_token');

        const appDeepLinkUrl = `menupro://pos-self/${id}?access_token=${accessToken}&table_identifier=${tableIdentifier}&restoId=${restoId}`;
        const result = handleDeepLinkRedirection(appDeepLinkUrl);
        setCheckOS(result);

    }, [id, queryParams]);

    return (
        <div>

            {checkOS === true ? (
                <h3>This is intended for mobile devices only</h3>
            ) : (
                <h3>Redirecting</h3>
            )}


        </div>
    );
};
export default Fallback;