import React from 'react';
import { Link } from 'react-router-dom';


class Home_6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMore: {
        capturezPartagez: false,
        ajouterMenus: false,
        recompensesAvis: false,
        contribuerRestaurants: false
      }
    };
  }

  toggleShowMore = (section) => {
    this.setState(prevState => ({
      showMore: {
        ...prevState.showMore,
        [section]: !prevState.showMore[section]
      }
    }));
  }

  render() {
    const { showMore } = this.state;

    return (
      <div>


        {/* PRELOADER SPINNER
		============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}
        <div id="page" className="page">

          {/* HERO-6
			============================================= */}
          <section id="hero-6" className="hero-section division">
            <div className="container">
              <div className="row">
                {/* HERO TEXT */}
                <div className="col-lg-5">
                  <div className="hero-6-txt pc-25 white-color">
                    {/* <div className="hero-app-logo">
                      <img className="img-fluid" src="assets/images/Menuprologo.png" alt="hero-app-logo" />
                    </div> */}
                    {/* Title */}
                    <h2>Bienvenue, explorateur!</h2>
                    {/* Text */}
                    <p className="p-xl">Nous savons que vous êtes impatients d'utiliser MenuPro sur toutes les plateformes</p>
                    <div className="stores-badge">

                      <a href="https://apps.apple.com/us/app/menupro/id6464469617" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>

                      <a href="https://play.google.com/store/apps/details?id=com.menupro.app" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a>
                    </div>
                    {/* Rating */}
                    <div className="mb-50 txt-block-rating">
                      <div className="stars-rating">
                        Note des utilisateurs
                        <span className="flaticon-star ml-5" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>

                    </div>
                  </div>
                </div>
                {/* HERO IMAGE */}
                <div className="col-lg-5">
                  <div className="hero-6-img">
                    <img className="img-fluid" src="assets/images/3_phones.png" alt="hero-image" />
                  </div>
                </div>
              </div> 	  {/* End row */}
            </div>	    {/* End container */}
          </section>	{/* END HERO-6 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="wide-60 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="mt-65 img-fluid" src="assets/images/ScreenSearch.svg" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <span className="mb-5 section-id grey-color">Exploration Culinaire</span>
                      <hr />
                      {/* Title */}
                      <h3 className="h2-md">Découvrez les meilleures restaurants près de chez vous</h3>
                      {/* Text */}
                      <p className="p-lg"><span className="txt-highlight">Grâce à MenuPro</span>, l'application qui vous permet de trouver des pépites culinaires, de partager vos avis et de gagner des points à chaque contribution.
                      </p>
                      {/* Text */}
                      <p className="p-lg">Que <span className='txt-highlight'> vous cherchiez un restaurant par son nom </span>, <span className='txt-highlight'> un plat spécifique</span> ou <span className='txt-highlight'> par adresse</span>, MenuPro vous offre une plateforme intuitive pour explorer de nouveaux lieux, laisser des commentaires détaillés et bénéficier des recommandations de notre communauté passionnée.
                      </p>
                      {/* Text */}
                      <p className="p-lg">Rejoignez-nous et transformez chaque sortie au restaurant en une expérience enrichissante et gratifiante.
                      </p>

                    </div>
                  </div>	{/* END TEXT BLOCK */}
                </div>	  {/* End row */}
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1">
                <div className="mt-inverse-50 row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}
                      <span className="mb-5 section-id grey-color">Filtres de MenuPro</span>
                      <hr />
                      {/* Title */}
                      <h3 className="h2-md">Filtrez votre Recherche</h3>
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg"><span className="txt-highlight txt-500">Filtrez par Cuisine</span> Explorez une variété de cuisines en filtrant selon vos préférences gastronomiques.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg"><span className="txt-highlight txt-500">À Proximité</span> Trouvez des restaurants à proximité de votre emplacement actuel pour une expérience culinaire pratique.</p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg"><span className="txt-highlight txt-500">Mieux Notée</span> Consultez les restaurants les mieux notés par la communauté pour des choix sûrs et savoureux.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg"><span className="txt-highlight txt-500">Prix Moyen</span> Réglez votre recherche en fonction du prix moyen pour trouver des options correspondant à votre budget.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="mt-90 img-fluid" src="assets/images/filters.svg" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}
          {/* FEATURES-4
			============================================= */}
          <section id="features-4" className="pt-50 features-section division">
            <div className="container">
              <div className="mt-inverse-50 row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    <h2 className="h2-md">Évaluez, Partagez et Découvrez</h2>
                    <p className="p-xl">
                      Avec MenuPro, évaluez vos expériences culinaires, partagez vos avis et découvrez de nouveaux restaurants recommandés par notre communauté passionnée.
                    </p>
                  </div>
                </div>
              </div>
              <div className="fbox-4-wrapper">
                <div className="row">
                  <div id="fb-4-1" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.4s">
                      <a href="#capturez-partagez" className="card-link">
                        <div className="row d-flex align-items-center m-row">
                          <div className="col-lg-8 m-bottom">
                            <div className="fbox-4-txt">
                              <h5 className="h5-sm">Contribuez en Ajoutant des Restaurants</h5>
                            </div>
                          </div>
                          <div className="col-lg-4 m-top">
                            <div className="fbox-4-ico ico-100 text-center red-color">
                              <img src="assets/icons/shop.png" />
                            </div>
                          </div>
                        </div>
                      </a>

                    </div>
                  </div>
                  <div id="fb-4-2" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.6s">
                      <a href="#capturez-partagez" className="card-link">
                        <div className="row d-flex align-items-center m-row">
                          <div className="col-lg-8 m-bottom">
                            <div className="fbox-4-txt">
                              <h5 className="h5-sm">Ajoutez des Menus de Restaurants</h5>
                            </div>
                          </div>
                          <div className="col-lg-4 m-top">
                            <div className="fbox-4-ico ico-100 text-center red-color">
                              <img src="assets/icons/food.png" />
                            </div>
                          </div>
                        </div>
                      </a>

                    </div>
                  </div>
                  <div id="fb-4-3" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="0.8s">
                      <a href="#capturez-partagez" className="card-link">
                        <div className="row d-flex align-items-center m-row">
                          <div className="col-lg-8 m-bottom">
                            <div className="fbox-4-txt">
                              <h5 className="h5-sm">Récompenses pour Vos Avis</h5>
                            </div>
                          </div>
                          <div className="col-lg-4 m-top">
                            <div className="fbox-4-ico ico-100 text-center red-color">
                              <img src="assets/icons/dish.png" alt="" />
                            </div>
                          </div>
                        </div>
                      </a>

                    </div>
                  </div>
                  <div id="fb-4-4" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="1s">
                      <a href="#capturez-partagez" className="card-link">
                        <div className="row d-flex align-items-center m-row">
                          <div className="col-lg-8 m-bottom">
                            <div className="fbox-4-txt">
                              <h5 className="h5-sm">Capturez et Partagez Vos Plats</h5>
                            </div>
                          </div>
                          <div className="col-lg-4 m-top">
                            <div className="fbox-4-ico ico-100 text-center red-color">
                              <img src="assets/icons/burger.png" />
                            </div>
                          </div>
                        </div>
                      </a>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* CONTENT-5
			============================================= */}
          <section id="content-5" className="pb-80 content-section">
            <div className="bg-inner bg_whitesmoke pt-100 division">
              <div className="container">
                <div className="row d-flex align-items-center">
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <div id="capturez-partagez">
                        <span className="mb-5 section-id grey-color">Partagez et Gagnez</span>
                        <hr />
                        <div className="cbox mb-30">
                          <h3 className="h2-md">Capturez et Partagez Vos Plats</h3>
                          <div className="cbox-ico ico-65 red-color">
                            <img src="assets/icons/burger.png" />
                          </div>
                          <div className="cbox-txt">
                            <h5 className="h5-sm">Prendre une Photo</h5>
                            <p className="p-lg">
                              Partager la plus belle photo de votre plat et gagnez des points.
                              <span style={{ display: 'inline' }}>
                                {showMore.capturezPartagez && (
                                  <> Immortalisez vos plats préférés avec des clichés parfaits pour inspirer et faire saliver la communauté MenuPro.</>
                                )}
                              </span>
                              <span
                                className="text-link"
                                onClick={() => this.toggleShowMore('capturezPartagez')}
                                role="button"
                                aria-expanded={showMore.capturezPartagez}
                                style={{ marginLeft: '5px' }}
                              >
                                {showMore.capturezPartagez ? 'Voir moins' : 'Voir plus'}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="cbox mb-30" id="ajouter-menus">
                        <div className="cbox-ico ico-65 red-color"><img src="assets/icons/food.png" alt="Ajouter un Menu" /></div>
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Ajouter un Menu</h5>
                          <p className="p-lg">
                            Ajouter le menu d'un resto et gagnez des points.
                            <span style={{ display: 'inline' }}>
                              {showMore.ajouterMenus && (
                                <>   Prenez des photos claires et précises des menus pour aider les autres utilisateurs à découvrir les meilleures options culinaires. Plus de détails supplémentaires peuvent être ajoutés ici si nécessaire.</>
                              )}
                            </span>

                            <span
                              className="text-link"
                              onClick={() => this.toggleShowMore('ajouterMenus')}
                              role="button"
                              aria-expanded={showMore.ajouterMenus}
                              style={{ marginLeft: '5px' }}
                            >
                              {showMore.ajouterMenus ? 'Voir moins' : 'Voir plus'}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="cbox mb-30" id="recompenses-avis">
                        <h3 className="h2-md">Partagez Vos Expériences Culinaires</h3>
                        <div className="cbox-ico ico-65 red-color"><img src="assets/icons/dish.png" alt="" /></div>
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Donner votre Avis</h5>
                          <p className="p-lg">
                            Partager votre avis et gagnez des points.
                            <span style={{ display: 'inline' }}>
                              {showMore.recompensesAvis && (
                                <>
                                  Partagez avec nous vos meilleures expériences en notant, ajoutant des photos et des commentaires détaillés pour guider les autres gourmets. Détails supplémentaires pour donner votre avis et interagir avec la communauté.
                                </>
                              )}
                            </span>


                            <span
                              className="text-link"
                              onClick={() => this.toggleShowMore('recompensesAvis')}
                              role="button"
                              aria-expanded={showMore.recompensesAvis}
                              style={{ marginLeft: '5px' }}
                            >
                              {showMore.recompensesAvis ? 'Voir moins' : 'Voir plus'}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="cbox mb-30" id="contribuer-restaurants">
                        <div className="cbox-ico ico-65 red-color"><img src="assets/icons/shop.png" alt="Ajouter un Restaurant" /></div>
                        <div className="cbox-txt">
                          <h5 className="h5-sm">Ajouter un Restaurant</h5>
                          <p className="p-lg">
                            Ajouter les coordonnées d'un resto et gagnez des points.
                            <span style={{ display: 'inline' }}>
                              {showMore.contribuerRestaurants && (
                                <>
                                  Participez à l'enrichissement de notre base de données en ajoutant de nouveaux établissements et leurs informations essentielles. Détails supplémentaires pour ajouter un restaurant et ses informations.
                                </>
                              )}

                            </span>


                            <span
                              className="text-link"
                              onClick={() => this.toggleShowMore('contribuerRestaurants')}
                              role="button"
                              aria-expanded={showMore.contribuerRestaurants}
                              style={{ marginLeft: '5px' }}
                            >
                              {showMore.contribuerRestaurants ? 'Voir moins' : 'Voir plus'}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-6 text-center">
                    <div className="content-5-img pc-20 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/features.svg" alt="content-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>


          {/* CONTENT-11
			============================================= */}
          <section id="content-11" className="pb-100 pt--5 content-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">
                    {/* Title */}
                    <h2 className="h2-md">Gagnez chaque mois avec MenuPro</h2>
                    {/* Text */}
                    <p className="p-xl">Chez MenuPro, cumulez des points chaque mois en partageant vos avis et photos de menus, et en ajoutant de nouveaux restaurants.<span className='txt-highlight'>Les trois meilleurs de chaque classement mensuel (Tunisie)</span>  gagnent des chèques restaurant.
                    </p>
                  </div>
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/gifts.svg" alt="content-image" />
                  </div>
                </div>
              </div>
              {/* DOWNLOAD BUTTON */}
              <div className="row">

                <div className="col-md-12">
                  <div className="content-11-btn ico-20 ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Button */}
                    <a href="https://www.instagram.com/reel/C7HY6LZI0T2/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==" className="video-popup2 btn btn-md btn-skyblue tra-grey-hover">
                      Voir les gagnants de MenuPro  <span className="flaticon-play-button" />
                    </a>
                    {/* OS Prerequisite */}
                    <span className="os-version">Disponible sur Android, iOS et macOS</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}
            </div>	   {/* End container */}
          </section>
          {/* TESTIMONIALS-4
			============================================= */}

          {/* CONTENT-2
			============================================= */}
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="pb-60 content-section division">
            <div className="container">
              <div className="mt-inverse-50 row d-flex align-items-center">
                {/* TEXT BLOCK */}
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    <span className="mb-5 section-id grey-color">Optimisé pour Vous</span>
                    <hr />

                    {/* Title */}
                    <h3 className="h2-md">Fonctionnalités intuitives, résultats précis</h3>
                    {/* List */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Notre application est conçue pour vous offrir une expérience utilisateur fluide et intuitive, que ce soit pour noter vos plats préférés, partager vos avis ou découvrir de nouveaux restaurants.
                          <br />
                          Avec MenuPro, tout est pensé pour vous faciliter la vie.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Trouvez les meilleurs restaurants autour de vous avec facilité et précision grâce à notre interface utilisateur optimisée.
                        </p>
                      </li>
                    </ul>
                    {/* Button */}
                    <a href="/about" className="btn btn-tra-rose tra-rose-hover">Find Out More</a>
                  </div>
                </div>	{/* END TEXT BLOCK */}
                {/* IMAGE BLOCK */}
                <div className="col-md-6 col-xl-6">
                  <div className="mt-60 content-13-img wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/tablet1.svg" alt="content-image" />
                  </div>
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* CONTENT-14
			============================================= */}

          {/* BRANDS-2

        
          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-1" className="bg_cover pt-100 download-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center m-row">

                {/* TEXT BLOCK */}
                <div className="col-md-12 col-lg-8 m-top">
                  <div className="txt-block left-column pc-20 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Title */}
                    <h2 className="h2-md">Découvrez MenuPro</h2>
                    {/* Text */}
                    <p className="p-xl">Rejoignez notre communauté et découvrez les meilleurs restaurants à travers des avis honnêtes et des recommandations fiables.</p>
                    {/* Button */}
                    <a href="/follow_us" className="btn btn-tra-white ml-200">Suivez nous</a>
                    <div className='row d-flex align-items-center m-row'>
                      <div className="col-md-6 col-lg-6">
                        <h2 className="ml-70 mt-40 h2-md">160K+</h2>
                        {/* Text */}
                        <p className="mt-inverse-30 p-xl">Abonnées sur les réseau sociaux</p>
                        <div className="ml-60 mb-40 row stores-badge">
                          {/* AppStore */}
                          <a href="https://www.instagram.com/menu_pro/" className="store">
                            <img className="appstore-white mr-10" src="assets/images/icons/insta.png" alt="appstore-logo" />
                          </a>
                          {/* Google Play */}
                          <a href="https://www.facebook.com/p/MenuPro-61551419732945/" className="store">
                            <img className="googleplay-white mr-10" src="assets/images/icons/fcb.png" alt="googleplay-logo" />
                          </a>
                          <a href="https://www.tiktok.com/@menupro.app" className="store">
                            <img className="googleplay-white mr-10" src="assets/images/icons/tiktok.png" alt="googleplay-logo" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <h2 className="ml-70 mt-40 h2-md">80K+</h2>
                        {/* Text */}
                        <p className="ml-50 mt-inverse-30 p-xl">Téléchargements</p>
                        {/* STORE BADGES */}
                        <div className="row mb-40 stores-badge">
                          {/* AppStore */}
                          <a href="https://apps.apple.com/us/app/menupro/id6464469617" className="store">
                            <img className="appstore-white" src="assets/images/appstore.png" alt="appstore-logo" />
                          </a>
                          {/* Google Play */}
                          <a href="https://play.google.com/store/apps/details?id=com.menupro.app" className="store">
                            <img className="googleplay-white" src="assets/images/googleplay.png" alt="googleplay-logo" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}
                {/* IMAGE BLOCK */}
                {/* <div className="col-md-4 col-lg-6 m-bottom">
                  <div className="img-block right-column pc-25 wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-15.png" alt="content-image" />
                  </div>
                </div> */}
              </div>    {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END DOWNLOAD-1 */}
          {/* FAQs-2
			============================================= */}
          {/* DOWNLOAD-1
			============================================= */}
          <section id="reviews-4" className="bg_aliceblue wide-60 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">
                    {/* Title */}
                    <h2 className="h2-md">L'avis de nos utilisateurs</h2>


                    <p className="p-xl">
                      Découvrez pourquoi nos utilisateurs apprécient tant MenuPro : des avis honnêtes, des recommandations fiables et une expérience culinaire exceptionnelle.
                    </p>
                  </div>
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Application utile et tres riches en contenu. <span className="txt-highlight"> Un compagnon fiable pour trouver les bonnes adresses </span>
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Minh</h5>
                          <p>6 janvier 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Superbe application avec
                            <span className="txt-highlight">des recherches avancées </span> et <span className="txt-highlight">des données actualisés </span>sur les restaurants.
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Ghaith ABDESSALEM</h5>
                          <p>11 décembre 2023</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> <span className="txt-highlight">Excellent guide pour trouver un restaurant de qualité.</span> Cette application est à la fois simple et efficace, je la recommande vivement !!
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">ben zouari intissar</h5>
                          <p>8 décembre 2023</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>

                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Excellent 👍 de <span className="txt-highlight"> trouver vôtre gastronomie favorite</span>
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Mabrouk Hammadi</h5>
                          <p>22 mars 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}


                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">

                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Super application pour
                            <span className="txt-highlight">trouver les bons plans</span> .
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Youssef BACCOUCHE</h5>
                          <p>23 juin 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Cool app!
                            Smooth user experience, very helpful! <span className="txt-highlight">I recommend it </span> .
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Fedy Belaid</h5>
                          <p>22 Oct juin 2023</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>J'ai bien aimé l'idée et l'expérience utilisateur, ya3tikom essa7a❤.
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">rim mathlouthi</h5>
                          <p>11 décembre 2023</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> Superbe application ! Intuitive, utile et très riche en contenu !! <span className="txt-highlight"> Une application tunisienne pour les Tunisiens.</span> Cette application est à la fois simple et efficace, je la recommande vivement !!
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">mo mo</h5>
                          <p>12 mars 2024</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> Enfin une application utile pour  <span className="txt-highlight">trouver les meilleures restaurant en Tunisie.</span>
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Momo Ay </h5>
                          <p>11 octobre 2023</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                  {/* TESTIMONIALS LEFT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
              {/* DOWNLOAD BUTTON */}
              <div className="row">

                <div className="col-md-12">
                  <div className="content-11-btn ico-20 ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Button */}
                    <a href="/reviews" className="video-popup2 btn btn-md btn-skyblue tra-grey-hover">
                      Voir plus d'avis
                    </a>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}
              <div className="row">
                <div className="col-md-12">
                  <div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">
                    {/* STORE BADGES */}
                    <div className="stores-badge mb-10">
                      {/* AppStore */}
                      <a href="https://apps.apple.com/us/app/menupro/id6464469617" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="https://play.google.com/store/apps/details?id=com.menupro.app" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a>
                    </div>	{/* END STORE BADGES */}
                    {/* OS Prerequisite */}
                    <span className="os-version">Disponible sur Android, et  iOS  </span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-4 */}
          {/* BLOG-1
			============================================= */}

          {/* NEWSLETTER-1
			============================================= */}
          <section id="newsletter-1" className="pb-20 newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg_white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}
                  <div className="col-lg-6">
                    <div className="newsletter-txt">
                      {/* Section ID */}
                      <span className="section-id">Rejoignez-nous</span>
                      {/* Title */}
                      <h4 className="h4-xl">Intégrez une famille dynamique et passionnée</h4>
                    </div>
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">

                        <span className="ml-300 input-group-btn">
                          <Link to="/join_Us">
                            <button type="submit" className="btn btn-md btn-skyblue tra-skyblue-hover">Rejoinez nous</button>
                          </Link>
                        </span>
                      </div>
                      {/* Newsletter Form Notification */}
                      <label htmlFor="s-email" className="form-notification" />
                    </form>
                  </div>	  {/* END NEWSLETTER FORM */}
                </div>	  {/* End row */}
              </div>    {/* End newsletter-holder */}
            </div>	   {/* End container */}
          </section>	{/* END NEWSLETTER-1 */}

        </div>	{/* END PAGE CONTENT */}


      </div>
    )
  }
}
export default Home_6 