import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Follow_us extends React.Component {
  render() {
    return (
      <div>


        {/* PRELOADER SPINNER
    ============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>


        {/* PAGE CONTENT
    ============================================= */}
        <div id="page" className="page">
          {/* HERO-11
			============================================= */}
          <section id="hero-11" className="bg_shape_01 hero-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center">
                {/* HERO TEXT */}
                <div className="col">
                  <div className="hero-11-txt text-center">
                    {/* Title */}
                    <h2 className="h2-lg">Découvrez notre succès sur les réseaux sociaux !</h2>
                    {/* Text */}
                    <p>Rejoignez notre communauté florissante sur Instagram et TikTok. Suivez-nous pour des mises à jour exclusives, des photos appétissantes, et bien plus encore.
                    </p>
                    {/* HERO IMAGE */}
                    <div className="hero-11-img">
                      <img className="img-fluid3" src="assets/images/insta_element.svg" alt="video-preview" />
                    </div>
                  </div>
                </div>	{/* END HERO TEXT */}
              </div>	  {/* End row */}
            </div>	   {/* End container */}
            {/* SECTION OVERLAY */}
            <div className="bg_fixed wave_overlay" />
          </section>	{/* END HERO-11 */}
          {/* BLOG POSTS LISTING
			============================================= */}
          <section id="blog-page" className="hero-offset-nav pb-60 blog-page-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="section-title text-center pc-50 mb-70">
                    {/* Title */}
                    <h3 className="h3-lg">Les succès de notre communauté</h3>
                    {/* Text */}
                    <p className="p-xl">Explorez les tendances culinaires et les moments marquants partagés par notre communauté
                    </p>
                  </div>
                </div>
              </div>
              {/* FEATURED POST */}
              <div className="rel blog-post featured-post wide-post">
                <div className="row d-flex align-items-center">
                  <div className="featured-badge text-center ico-30 bg_whitesmoke yellow-color">
                    <span className="flaticon-star" />
                  </div>
                  {/* BLOG POST IMAGE */}
                  <div className="col-lg-7 blog-post-img">
                    <img className="ml-20 img-fluid" src="assets/images/insta_feedback.png" alt="blog-post-image" />
                  </div>
                  {/* BLOG POST TEXT */}
                  <div className="col-lg-5 blog-post-txt">
                    {/* Post Tag */}
                    <p className="p-md post-tag">Coups de cœur</p>

                    {/* Post Link */}
                    <h5 className="h5-xl">
                      <a href="single-post.html">MenuPro : Votre guide vers les meilleures adresses</a>
                    </h5>
                    {/* Text */}
                    <p className="p-md">Découvrez les adresses culinaires recommandées par notre communauté Instagram grâce à MenuPro.
                    </p>
                    
                  </div>	{/* END BLOG POST TEXT */}
                </div>   {/* End row */}
              </div>	{/* END FEATURED POST */}

            </div>     {/* End container */}
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>	{/* END BLOG POSTS LISTING */}

          {/* FEATURES-3
			============================================= */}
          <section id="features-3" className="features-section division" >
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="rose-color h2-md">MenuPro en chiffres</h2>
                    {/* Text */}
                    <p className="p-xl">Voici quelques chiffres impressionnants sur notre communauté florissante.
                    </p>
                  </div>
                </div>
              </div>

              {/* FEATURES-3 WRAPPER */}
              <div className="fbox-3-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-3-1" className=" col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-3-ico grey-color ico-80"> <img className="googleplay-white mr-10" src="assets/images/icons/follow.svg" alt="follow" /></div>
                      {/* Title */}
                      <h5 className="rose-color h5-sm">10k</h5>
                      {/* Text */}
                      <p className="rose-color p-md">Followers</p>
                    </div>
                  </div>
                  {/* FEATURE BOX #2 */}
                  <div id="fb-3-2" className="col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-3-ico grey-color ico-80"> <img className="googleplay-white mr-10" src="assets/images/icons/Like.svg" alt="like" /></div>
                      {/* Title */}
                      <h5 className="rose-color h5-sm">25k</h5>
                      {/* Text */}
                      <p className="rose-color p-md">Likes</p>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-3-3" className="col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-3-ico grey-color ico-80"><img className="googleplay-white mr-10" src="assets/images/icons/vue.svg" alt="vue" /></div>
                      {/* Title */}
                      <h5 className="rose-color h5-sm">10k</h5>
                      {/* Text */}
                      <p className="rose-color p-md">Vues</p>
                    </div>
                  </div>
                  {/* FEATURE BOX #4 */}
                  <div id="fb-3-4" className="col-md-6 col-lg-3">
                    <div className="fbox-3 bg_white wow fadeInUp" data-wow-delay="1s">
                      {/* Icon */}
                      <div className="fbox-3-ico grey-color ico-80"><img className="googleplay-white mr-10" src="assets/images/icons/share.svg" alt="share" /></div>
                      {/* Title */}
                      <h5 className="rose-color h5-sm">10k</h5>
                      {/* Text */}
                      <p className="rose-color p-md">Partages</p>
                    </div>
                  </div>
                </div>  {/* End row */}
              </div>	{/* END FEATURES-3 WRAPPER */}
            </div>	   {/* End container */}
          </section > {/* END FEATURES-3 */}

          {/* FEATURES-3
			============================================= */}
          <section id="features-3" className="mt-100 features-section division" >
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="h2-md">Restez Connectés avec MenuPro</h2>
                    {/* Text */}
                    <p className="p-xl">Suivez nos aventures culinaires sur Instagram, TikTok et Facebook.
                    </p>
                  </div>
                </div>
              </div>
              {/* POSTS WRAPPER */}
              <div className="posts-wrapper">

              <div className="row">
                  {/* BLOG POST #1 */}
                  <div className="col-md-6 col-lg-4">
                    <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                      {/* BLOG POST IMAGE */}
                      <div className="blog-post-img">
                        <img className="img-fluid" src="assets/images/blog/Frame insta.png" alt="blog-post-image" />
                      </div>
                      {/* BLOG POST TEXT */}
                      <div className="blog-post-txt">
                        {/* Post Link */}
                        <h5 className="h5-xs">
                          <a href="single-post.html">Instagram</a>
                        </h5>
                        {/* Text */}
                        <p className="p-md">Découvrez les dernières publications, vidéos et stories de MenuPro. Ne manquez pas nos exclusivités et rejoignez notre communauté passionnée de gastronomie.
                        </p>
                        {/* Button */}
                        <a href="https://www.instagram.com/menu_pro/" className="btn btn-tra-rose ml-50">Consulter</a>
                      </div>
                    </div>
                  </div>	{/* END  BLOG POST #1 */}
                  {/* BLOG POST #2 */}
                  <div className="col-md-6 col-lg-4">
                    <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* BLOG POST IMAGE */}
                      <div className="blog-post-img">
                        <img className="img-fluid" src="assets/images/blog/Frame tiktok.png" alt="blog-post-image" />
                      </div>
                      {/* BLOG POST TEXT */}
                      <div className="blog-post-txt">
                        {/* Post Link */}
                        <h5 className="h5-xs">
                          <a href="single-post.html">Tiktok</a>
                        </h5>
                        {/* Text */}
                        <p className="p-md">Regardez nos vidéos les plus populaires, découvrez des recettes tendance et participez à nos défis culinaires.
                        </p>
                        {/* Button */}
                        <a href="https://www.tiktok.com/@menupro.app" className="mt-60 btn btn-tra-rose ml-50">Consulter</a>
                      </div>	{/* END BLOG POST TEXT */}
                    </div>
                  </div>	{/* END  BLOG POST #2 */}
                  {/* BLOG POST #3 */}
                  <div className="col-md-6 col-lg-4">
                    <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* BLOG POST IMAGE */}
                      <div className="blog-post-img">
                        <img className="img-fluid" src="assets/images/blog/frame facebook.png" alt="blog-post-image" />
                      </div>
                      {/* BLOG POST TEXT */}
                      <div className="blog-post-txt">
                        {/* Post Link */}
                        <h5 className="h5-xs">
                          <a href="single-post.html">Facebook</a>
                        </h5>
                        {/* Text */}
                        <p className="p-md"> Suivez notre page Facebook pour des mises à jour régulières, des événements en direct, et des discussions passionnantes avec d'autres amateurs de cuisine.
                        </p>
                        {/* Button */}
                        <a href="https://www.facebook.com/p/MenuPro-61551419732945/" className="mt-30 btn btn-tra-rose ml-50">Consulter</a>
                      </div>	{/* END BLOG POST TEXT */}
                    </div>
                  </div>	{/* END  BLOG POST #3 */}


                </div>  {/* End row */}
              </div>	{/* END POSTS WRAPPER */}

            </div>	   {/* End container */}
          </section > {/* END FEATURES-3 */}





          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="mt-90 bg_tiktok wide-400 content-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft mt-inverse-120" data-wow-delay="0.6s">
                    {/* Section ID */}
                    <span className="section-id"></span>
                    {/* Title */}
                    <h2 className="h2-md"></h2>
                    {/* List */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"></p>
                      </li>

                    </ul>

                  </div>
                </div>	{/* END TEXT BLOCK */}
                {/* IMAGE BLOCK */}
                <div className="col-md-6 col-xl-6">
                  <div className="content-14-img wow fadeInRight" data-wow-delay="0.6s">

                  </div>
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
        </div>  {/* END PAGE CONTENT */}



      </div>
    )
  }
}
export default Follow_us 