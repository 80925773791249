import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class About extends React.Component {
  render() {
    return (
      <div>


        {/* PRELOADER SPINNER
		============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE
		============================================= */}
        <div id="page" className="page">

          {/* PAGE HERO
			============================================= */}
          <div id="about-page" className="rel custom_gradient  page-hero-section division">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="hero-txt text-center white-color">
                    {/* Title */}
                    <h2 className="h2-md">À propos de nous</h2>
                    {/* Text */}
                    <p className="p-xl">Découvrez notre passion pour la gastronomie et notre engagement à vous offrir les meilleures recommandations culinaires avec MenuPro.
                    </p>
                  </div>
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </div>	{/* END PAGE HERO */}
          {/* ABOUT-2
			============================================= */}
          <div id="about-2" className="wide-90 about-section division">
            <div className="container">
              {/* TEXT BLOCK */}
              <div className="about-2-txt pc-50">
                {/* SMALL TITLE */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="txt-block mb-35">
                      <h5 className="h5-lg">Nous croyons au pouvoir du design simple</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* LEFT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block top-box">
                      {/* Text */}
                      <p className="p-lg"><span className='txt-highlight'>Chez MenuPro</span>, nous sommes convaincus que la simplicité est la clé pour une expérience utilisateur optimale.<span className='txt-highlight'>Un design épuré permet de naviguer facilement et de se concentrer sur l'essentiel </span> : la découverte culinaire.
                      </p>
                      {/* Text */}
                      <p className="p-lg">Notre application est conçue pour être intuitive, accessible et agréable à utiliser, afin que chaque utilisateur puisse explorer et apprécier les trésors gastronomiques sans aucune difficulté.
                      </p>
                    </div>
                  </div>	{/* END LEFT COLUMN */}
                  {/* RIGHT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block">
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg"><span className='txt-highlight'> Un design simple </span> ne signifie pas un manque de fonctionnalité. Au contraire, il permet de mettre en avant ce qui compte vraiment, en éliminant les distractions superflues.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Chaque élément de notre interface est soigneusement pensé pour offrir une utilisation fluide et directe.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Nous croyons que la beauté réside dans la simplicité et que cette approche rend l'expérience de nos utilisateurs non seulement efficace, mais aussi agréable et mémorable.
                          </p>
                        </li>
                      </ul>	{/* End List */}
                    </div>
                  </div>	{/* END RIGHT COLUMN */}
                </div>
              </div>	{/* END TEXT BLOCK */}
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block mt-50 mb-70">
                    <img className="img-fluid" src="assets/images/section_2.svg" alt="content-image" />
                  </div>
                </div>
              </div>
              {/* TEXT BLOCK */}
              <div className="about-2-txt pc-50">
                <div className="row">
                  {/* LEFT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block top-box">
                      {/* Title */}
                      <h5 className="h5-lg">Nous attachons de l'importance aux détails</h5>
                      {/* Text */}
                      <p className="p-lg">Chaque détail compte lorsqu'il s'agit de créer une application exceptionnelle.Chez MenuPro, nous mettons un point d'honneur à soigner chaque aspect de notre plateforme, des fonctionnalités aux interfaces, pour offrir une expérience sans faille.
                      </p>
                      {/* Text */}
                      <p className="p-lg">Nous savons que ce sont les petites choses qui font la différence et nous nous efforçons de rendre chaque interaction aussi fluide et agréable que possible.
                      </p>
                    </div>
                  </div>	{/* END LEFT COLUMN */}
                  {/* RIGHT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block">
                      {/* Title */}
                      <h5 className="h5-lg">Avec savoir-faire, compétences et travail acharné</h5>
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg"><span className='txt-highlight'>MenuPro est le fruit de la passion, du savoir-faire et du travail acharné de notre équipe. </span> Nous combinons nos compétences en technologie, en design et en gastronomie pour créer une application qui répond aux attentes des amateurs de cuisine les plus exigeants.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Notre engagement envers l'excellence nous pousse à innover constamment et à améliorer notre service pour vous offrir une expérience culinaire unique et enrichissante.
                          </p>
                        </li>

                      </ul>	{/* End List */}
                    </div>
                  </div>	{/* END RIGHT COLUMN */}
                </div>
              </div>	{/* END TEXT BLOCK */}
              {/* BUTTON */}
              <div className="row">
                <div className="col-md-12">
                  <div className="about-2-btn text-center mt-40">
                    <a href="#reviews-4" className="btn btn-md btn-skyblue tra-skyblue-hover">Read the Reviews</a>
                  </div>
                </div>
              </div>	{/* END BUTTON */}
            </div>	   {/* End container */}
          </div>	{/* END ABOUT-2 */}
          {/* TESTIMONIALS-1
			============================================= */}
          <section id="reviews-4" className="bg_aliceblue wide-60 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">
                    {/* Title */}
                    <h2 className="h2-md">Ce qui rend nos utilisateurs heureux</h2>


                    <p className="p-xl">
                      Découvrez pourquoi nos utilisateurs apprécient tant MenuPro : des avis honnêtes, des recommandations fiables et une expérience culinaire exceptionnelle.
                    </p>
                  </div>
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                     
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Super application pour
                            <span className="txt-highlight">trouver les bons plans</span> .
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star-half-empty"></span>
                          </div>
                          <h5 className="h5-sm">Youssef BACCOUCHE</h5>
                          <p>23 juin 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> Superbe application ! Intuitive, utile et très riche en contenu !! <span className="txt-highlight"> Une application tunisienne pour les Tunisiens.</span> Cette application est à la fois simple et efficace, je la recommande vivement !!
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">mo mo</h5>
                          <p>12 mars 2024</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
              {/* DOWNLOAD BUTTON */}
              <div className="row">
                <div className="col-md-12">
                  <div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">
                    {/* STORE BADGES */}
                    <div className="stores-badge mb-10">
                      {/* AppStore */}
                      <a href="https://apps.apple.com/us/app/menupro/id6464469617" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="https://play.google.com/store/apps/details?id=com.menupro.app" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a>
                    </div>	{/* END STORE BADGES */}
                    {/* OS Prerequisite */}
                    <span className="os-version">Available for iOS 11 and Android Devices </span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-4 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid2" src="assets/images/food_design.svg" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}
                    <span className="section-id grey-color">Simplicité Culinaire</span>
                    {/* Title */}
                    <h3 className="h2-md">Transformez Chaque Repas en une Expérience Inoubliable</h3>
                    {/* Text */}
                    <p className="p-lg">Chez MenuPro, nous rêvons et créons des solutions numériques incroyables pour les amateurs de cuisine.
                    </p>
                    {/* Text */}
                    <p className="p-lg">Plongez dans un univers où la simplicité et l'élégance se rencontrent. Notre application intuitive vous permet de découvrir des trésors culinaires, de partager vos expériences et de gagner des points à chaque contribution.
                    </p>
                    {/* Text */}
                    <p className="p-lg">Avec MenuPro, explorez, évaluez et savourez les meilleures expériences gastronomiques.
                    </p>
                  </div>
                </div>	{/* END TEXT BLOCK */}
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* BRANDS-2
			============================================= */}
          {/* SECTION DIVIDER
			============================================= */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>
          {/* TEAM-1
			============================================= */}
          <section id="team-1" className="wide-60 team-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="h2-md">Proposé par <span className='txt-highlight'>Visto Consulting </span> </h2>
                    {/* Text */}
                    <p className="p-xl" > Société de systèmes d'information et de services technologiques proposant des solutions commerciales complètes.
                    </p>
                  </div>
                </div>
              </div>
              {/* Text */}
              <p className="p-xl"><span className='txt-highlight'>Nos services comprennent :</span> Des solutions collaboratives. Gestion de programmes et de projets et conseil en gestion.
              </p>
              <p className="p-xl">Nous aidons nos clients à obtenir un maximum d'économies et d'avantages en matière d'efficacité commerciale grâce à une sélection et une mise en œuvre efficaces de technologies appropriées. Nos clients comprennent des petites et moyennes entreprises ainsi que de grandes organisations.
              </p>
              <p className="p-xl"><span className='txt-highlight'>Nos valeurs : </span></p>
              <section id="features-1" className="wide-60 features-section division">
                <div className="container">
                  {/* FEATURES-1 WRAPPER */}
                  <div className="fbox-1-wrapper pc-30 text-center">
                    <div className="row">
                      {/* FEATURE BOX #1 */}
                      <div id="fb-1-1" className="col-md-3">
                        <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                          {/* Icon */}
                          <div className="fbox-1-ico ico-90 stateblue-color">  <img className="" src="assets/images/icons/respect.png" alt="" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Respect et intégrité</h5>

                        </div>
                      </div>
                      {/* FEATURE BOX #2 */}
                      <div id="fb-1-2" className="col-md-3">
                        <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                          {/* Icon */}
                          <div className="fbox-1-ico ico-90 stateblue-color">
                            <img className="" src="assets/images/icons/collaborate.png" alt="" />
                          </div>
                          {/* Title */}
                          <h5 className="h5-sm">Engagement</h5>

                        </div>
                      </div>
                      {/* FEATURE BOX #3 */}
                      <div id="fb-1-3" className="col-md-3">
                        <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                          {/* Icon */}
                          <div className="fbox-1-ico ico-90 stateblue-color"> <img className="" src="assets/images/icons/reliability.png" alt="" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Qualité</h5>

                        </div>
                      </div>
                      <div id="fb-1-3" className="col-md-3">
                        <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                          {/* Icon */}
                          <div className="fbox-1-ico ico-90 stateblue-color"> <img className="" src="assets/images/icons/network.png" alt="" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Travail en équipe</h5>

                        </div>
                      </div>

                    </div>  {/* End row */}
                  </div>	{/* END FEATURES-1 WRAPPER */}
                </div>	   {/* End container */}
              </section>	{/* END FEATURES-1 */}

              <p className="p-xl"><span className='txt-highlight'>Notre Vision </span> est d'accompagner nos clients dans la mise en œuvre de projets réussis en apportant innovation, engagement et travail d'équipe.
              </p>
            </div>	    {/* End container */}
          </section>	{/* END TEAM-1 */}
          {/* NEWSLETTER-1
			============================================= */}
          <section id="newsletter-1" className="pb-20 newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg_white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}
                  <div className="col-lg-6">
                    <div className="newsletter-txt">
                      {/* Section ID */}
                      <span className="section-id">Rejoignez-nous</span>
                      {/* Title */}
                      <h4 className="h4-xl">Intégrez une famille dynamique et passionnée</h4>
                    </div>
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">

                        <span className="ml-300 input-group-btn">
                          <Link to="/join_Us">
                            <button type="submit" className="btn btn-md btn-skyblue tra-skyblue-hover">Rejoinez nous</button>
                          </Link>
                        </span>
                      </div>
                      {/* Newsletter Form Notification */}
                      <label htmlFor="s-email" className="form-notification" />
                    </form>
                  </div>	  {/* END NEWSLETTER FORM */}
                </div>	  {/* End row */}
              </div>    {/* End newsletter-holder */}
            </div>	   {/* End container */}
          </section>	{/* END NEWSLETTER-1 */}

        </div>	{/* END PAGE CONTENT */}



      </div>
    )
  }
}
export default About 