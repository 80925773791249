import React, { Component } from 'react';
import axios from 'axios';

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      sujet: '',
      message: '',
      emailError: false,
      nameError: false,
      sujetError: false,
      messageError: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const { email, name, sujet, message } = this.state;

    let hasError = false;

    if (!emailRegex.test(email)) {
      this.setState({ emailError: true });
      console.error("Invalid email address");
      hasError = true;
    } else {
      this.setState({ emailError: false });
    }

    if (name.trim() === '') {
      this.setState({ nameError: true });
      console.error("Invalid name");
      hasError = true;
    } else {
      this.setState({ nameError: false });
    }

    if (sujet.trim() === '') {
      this.setState({ sujetError: true });
      hasError = true;
    } else {
      this.setState({ sujetError: false });
    }

    if (message.trim() === '') {
      this.setState({ messageError: true });
      hasError = true;
    } else {
      this.setState({ messageError: false });
    }

    if (hasError) {
      return;
    }

    const formData = { email, name, sujet, message };

    axios
      .post('https://api.menupro.tn/contact/contact-us', formData)
      .then((response) => {
        console.log('Form data sent:', response.data);
        this.setState({ email: '', name: '', sujet: '', message: '' });
      })
      .catch((error) => {
        console.error('Error sending form data:', error);
      });
  };

  render() {
    const { email, name, sujet, message, emailError, nameError, sujetError, messageError } = this.state;
    return (
      <div>
        {/* PRELOADER SPINNER */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT */}
        <div id="page" className="page">
          {/* CONTACTS-2 */}
          <section id="contacts-2" className="bg_whitesmoke hero-offset-nav pb-50 contacts-section division">
            <div className="mt-50  container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">
                    <h2 className="h2-md">Contactez-nous</h2>
                  </div>
                </div>
              </div>
              {/* CONTACT FORM */}
              <div className="row">
                <div className="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                  <div className="form-holder">
                    <form name="contactform" className="row contact-form" onSubmit={this.handleSubmit}>
                      <div id="input-subject" className="col-md-12 input-subject">
                        <p className="p-lg">Cette question concerne :</p>
                        <span>Choisissez un sujet afin que nous puissions diriger votre demande :</span>
                        <select
                          id="inlineFormCustomSelect1"
                          name="sujet"
                          className="custom-select subject"
                          value={sujet}
                          onChange={this.handleInputChange}
                        >
                          <option>Cette question concerne...</option>
                          <option value="Inscription/Autorisation">Inscription/Autorisation</option>
                          <option value="Utilisation de l'application">Utilisation de l'application</option>
                          <option value="Résolution de problèmes">Résolution de problèmes</option>
                          <option value="Autre">Autre</option>
                        </select>
                      </div>
                      <div id="input-name" className="col-md-12">
                        <p className="p-lg">Votre nom :</p>
                        <span>Veuillez entrer votre vrai nom :</span>
                        <input
                          type="text"
                          name="name"
                          className="form-control name"
                          placeholder="Votre Nom*"
                          value={name}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div id="input-email" className="col-md-12">
                        <p className="p-lg">Votre adresse e-mail :</p>
                        <span>Veuillez vérifier attentivement votre adresse e-mail pour sa précision</span>
                        <input
                          type="text"
                          name="email"
                          className="form-control email"
                          placeholder="Adresse e-mail*"
                          value={email}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div id="input-message" className="col-md-12 input-message">
                        <p className="p-lg">Expliquez votre question en détail :</p>
                        <textarea
                          className="form-control message"
                          name="message"
                          rows={6}
                          placeholder="J'ai un problème avec..."
                          value={message}
                          onChange={this.handleInputChange}
                        />
                      </div>
                      <div className="col-md-12 mt-15 form-btn text-right">
                        <button type="submit" className="btn btn-skyblue tra-skyblue-hover submit">Envoyer la demande</button>
                      </div>
                      <div className="col-lg-12 contact-form-msg">
                        <span className="loading" />
                      </div>
                    </form>
                    {emailError && <div className="text-danger error-message">L'adresse email est invalide. Veuillez entrer une adresse email valide.</div>}
                    {nameError && <div className="text-danger error-message">Veuillez entrer votre nom.</div>}
                    {sujetError && <div className="text-danger error-message">Veuillez entrer un sujet.</div>}
                    {messageError && <div className="text-danger error-message">Veuillez entrer votre message.</div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg_fixed geometric_overlay" />
          </section>
          <div className="divider-wrapper text-center bg_whitesmoke"><div className="section-divider" /></div>
        </div>
      </div>
    );
  }
}

export default Contacts;
