import React, { Component } from 'react';

class Reviews extends React.Component {
  render() {
    return (
      <div>



        {/* PRELOADER SPINNER
		============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}
        <div id="page" className="page">

          {/* TESTIMONIALS-4
			============================================= */}
          <section id="reviews-4" className="bg_whitesmoke  hero-offset-nav pb-60 reviews-section division">
            <div className="mt-50 container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">
                    {/* Title */}
                    <h2 className="h2-md">L'avis de nos utilisateurs</h2>
                    {/* Text */}
                    <p className="p-xl"> Découvrez pourquoi nos utilisateurs apprécient tant MenuPro : des avis honnêtes, des recommandations fiables et une expérience culinaire exceptionnelle.
                    </p>
                  </div>
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Application utile et tres riches en contenu. <span className="txt-highlight"> Un compagnon fiable pour trouver les bonnes adresses </span>
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Minh</h5>
                          <p>6 janvier 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Superbe application avec
                            <span className="txt-highlight">des recherches avancées </span> et <span className="txt-highlight">des données actualisés </span>sur les restaurants.
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Ghaith ABDESSALEM</h5>
                          <p>11 décembre 2023</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> <span className="txt-highlight">Excellent guide pour trouver un restaurant de qualité.</span> Cette application est à la fois simple et efficace, je la recommande vivement !!
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">ben zouari intissar</h5>
                          <p>8 décembre 2023</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>

                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Excellent 👍 de <span className="txt-highlight"> trouver vôtre gastronomie favorite</span>
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Mabrouk Hammadi</h5>
                          <p>22 mars 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}


                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">

                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Super application pour
                            <span className="txt-highlight">trouver les bons plans</span> .
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Youssef BACCOUCHE</h5>
                          <p>23 juin 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Cool app!
                            Smooth user experience, very helpful! <span className="txt-highlight">I recommend it </span> .
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Fedy Belaid</h5>
                          <p>22 Oct juin 2023</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>J'ai bien aimé l'idée et l'expérience utilisateur, ya3tikom essa7a❤.
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">rim mathlouthi</h5>
                          <p>11 décembre 2023</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>La Meilleure.
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Neder Bchir</h5>
                          <p>20 mai 2024</p>
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> Superbe application ! Intuitive, utile et très riche en contenu !! <span className="txt-highlight"> Une application tunisienne pour les Tunisiens.</span> Cette application est à la fois simple et efficace, je la recommande vivement !!
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">mo mo</h5>
                          <p>12 mars 2024</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> Enfin une application utile pour  <span className="txt-highlight">trouver les meilleures restaurant en Tunisie.</span>
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Momo Ay </h5>
                          <p>11 octobre 2023</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}

                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}

                        <div className="review-4-txt">
                          <p> Très bonne idée bon courage.
                          </p>
                        </div>
                        <div className="review-4-data">
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                            <span className="flaticon-star"></span>
                          </div>
                          <h5 className="h5-sm">Grech Anis</h5>
                          <p>10 décembre 2023</p>

                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                  {/* TESTIMONIALS LEFT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
            </div>     {/* End container */}
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>	{/* END TESTIMONIALS-4 */}

          {/* DOWNLOAD-2
			============================================= */}
          <section id="download-2" className="bg_whitesmoke pb-20 download-section division">
            <div className="container white-color">
              <div className="rel purple_gradient bg_shape_01 downloads-2-wrapper">
                <div className="row d-flex align-items-center">
                  {/* DOWNLOAD TEXT */}
                  <div className="col-lg-7 col-lg-7">
                    <div className="download-2-txt white-color">
                      {/* Icon */}
                      <div className="download-2-logo">
                        <img className="img-fluid" src="assets/images/Menuprologo.png" alt="app-logo" />
                      </div>
                      {/* Title */}
                      <div className="d2-txt">
                        <h4 className="h4-md">Téléchargez MenuPro pour découvrir et partager !</h4>
                      </div>
                    </div>
                  </div>
                  {/* STORE BADGES */}
                  <div className="col-lg-5 text-right">
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="https://apps.apple.com/us/app/menupro/id6464469617" className="store">
                        <img className="appstore" src="assets/images/appstore-white.png" alt="appstore-logo" />
                      </a>
                      {/* Google Play */}
                      <a href="https://play.google.com/store/apps/details?id=com.menupro.app" className="store">
                        <img className="googleplay" src="assets/images/googleplay-white.png" alt="googleplay-logo" />
                      </a>
                    </div>
                  </div>	{/* END STORE BADGES */}
                </div>
              </div>    {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END DOWNLOAD-2 */}
        </div>	{/* END PAGE CONTENT */}



      </div>
    )
  }
}
export default Reviews 