import React, { Component } from 'react';

class Features extends React.Component {
  render() {
    return (
      <div>
        {/* PRELOADER SPINNER
		============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}
        <div id="page" className="page">
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="mt-90 bg_purple_img wide-20 content-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft mt-inverse-120" data-wow-delay="0.6s">
                    {/* Section ID */}
                    <span className="section-id">Gestion optimisée</span>
                    {/* Title */}
                    <h2 className="h2-md"> Outils de gestion restaurant de MenuPro</h2>
                    {/* List */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Optimisation Complète : Facilité d'utilisation, résultats puissants , et une interface adaptée à tous les appareils pour une gestion flexible et pratique
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Accessibilité Maximale : Gérez votre restaurant de n'importe où, à tout moment, accès simplifié depuis votre téléphone ou tout autre appareil </p>
                      </li>

                    </ul>
                    {/* Button */}
                    <a href="#content-11" className="btn btn-tra-white ">Voir plus</a>
                  </div>
                </div>	{/* END TEXT BLOCK */}
                {/* IMAGE BLOCK */}
                <div className="col-md-6 col-xl-6 ">
                  <div className="content-14-img wow fadeInRight mr-10" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/3 dev taille 3.svg" alt="content-image" />
                  </div>
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="bg_whitesmoke hero-offset-nav pb-60 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="mt-50 row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}
                      <span className="mb-5 section-id grey-color">Processus de connexion simplifié</span>
                      <hr />
                      {/* Title */}
                      <h3 className="h2-md">Connectez-vous à votre restaurant en quelques secondes.</h3>
                      {/* Text */}
                      <p className="p-lg">Connectez-vous rapidement à votre compte MenuPro restaurant pour accéder à l'<span className='txt-highlight'> espace de gestion de votre restaurant</span>.
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/TabletLogin.svg" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/profileResto.svg" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}
                      <span className="mb-5 section-id grey-color">Gestion efficace</span>
                      <hr />
                      {/* Title */}
                      <h3 className="h2-md">
                        Gestion efficace de votre restaurant</h3>
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Prenez le <span className='txt-highlight'>contrôle total de votre restaurant et de son menu </span> grâce aux puissants outils de gestion de MenuPro.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Mettez à jour facilement les détails de votre établissement,<span className='txt-highlight'>ajoutez, modifiez ou supprimez des menus, et ajustez les horaires d'ouverture</span>,  le tout depuis une page de profil restaurant pratique.</p>
                        </li>

                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}
                </div>	  {/* End row */}
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}
          {/* CONTENT-1
			============================================= */}
          <section id="content-1" className="bg_whitesmoke pb-60 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center m-row">
                {/* TEXT BLOCK */}
                <div className="col-md-7 col-lg-6 m-bottom">
                  <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    {/* Section ID */}
                    <span className="mb-5 section-id grey-color">Modération transparente</span>
                    <hr />
                    {/* Title */}
                    <h3 className="h2-md">Gestion transparente et informative</h3>
                    {/* List */}
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Surveillez facilement les avis des utilisateurs et les photos suggérées.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Le module de modération de MenuPro vous permet de consulter et de gérer les commentaires des clients et le contenu visuel associé à votre restaurant, garantissant une présence en ligne positive.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Suivez les métriques de feedback avec notre tableau de bord intuitif, offrant des insights sur le nombre d'avis et de photos suggérées pour votre restaurant.</p>
                      </li>
                    </ul>
                  </div>
                </div>	{/* END TEXT BLOCK */}
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-top">
                  <div className="img-block right-column pc-20 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/avis+image.svg" alt="content-image" />
                  </div>
                </div>
              </div>	   {/* End row */}

              <div id="cb-1-2" className="cbox-1">
                <div className="mt-50 row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/userProfile5.svg" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}
                      <span className="mb-5 section-id grey-color">Profils utilisateurs personnalisables</span>
                      <hr />
                      {/* Title */}
                      <h3 className="h2-md">
                        Gestion personnalisée des profils utilisateurs</h3>
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Gérez votre profil utilisateur de manière transparente au sein de MenuPro.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Modifiez vos détails et préférences pour personnaliser votre expérience et votre interaction avec notre plateforme, assurant une approche personnalisée de la gestion de la présence en ligne de votre restaurant.</p>
                        </li>

                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}
                </div>	  {/* End row */}
              </div>	{/* END CONTENT BOX-2 */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-1 */}
          {/* FEATURES-5
			============================================= */}


          {/* CONTENT-11
			============================================= */}
          <section id="content-11" className="bg_whitesmoke wide-100 content-section division">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">
                    {/* Title */}
                    <h2 className="h2-md">Accessible sur toutes les plateformes</h2>
                    {/* Text */}
                    <p className="p-xl">Le site de gestion du restaurant de MenuPro est accessible sur toutes les plateformes, offrant une expérience utilisateur fluide et cohérente, que ce soit sur ordinateur, tablette ou smartphone.
                    </p>
                  </div>
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/abstract_mockups.svg" alt="content-image" />
                  </div>
                </div>
              </div>

            </div>	   {/* End container */}
          </section>	{/* END CONTENT-11 */}
          {/* CONTENT-2
			============================================= */}
          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-6" className="bg_mobile wide-100 download-section division">
            <div className="container white-color">
              <div className="row">
                {/* DOWNLOAD TXT */}
                <div className="col-lg-6">
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}
                    <span className="section-id">Téléchargez l'Application</span>
                    {/* Title 	*/}
                    <h2 className="h2-md">Simplifiez la gestion avec MenuPro</h2>
                    {/* Text */}
                    <p className="p-xl">Découvrez une gestion intuitive et efficace de votre restaurant avec MenuPro.
                    </p>
                    {/* STORE BADGES */}
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a>
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */}
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */}
                    </div>	{/* END STORE BADGES */}
                  </div>
                </div>	{/* END DOWNLOAD TXT */}
              </div>    {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END  DOWNLOAD-6 */}
          {/* FOOTER-2
			============================================= */}

        </div>	{/* END PAGE CONTENT */}

      </div>
    )
  }
}
export default Features 