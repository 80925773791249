
// import './App.css';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import Features from './pages/Features.js';
import Pricing from './pages/Pricing.js';
import Reviews from './pages/Reviews.js';
import Single_post from './pages/Single_post.js';
import Privacy from './pages/PrivacyPolicy.js';
import About from './pages/About.js';
import Contacts from './pages/Contacts.js';
import Home_6 from './pages/Home_6.js';


// import {
//   BrowserRouter as Router,
//   Routes,
//   Route
// } from "react-router-dom";

import { Routes, Route, useLocation } from "react-router-dom";
import Terms from './pages/Terms&Conditions.js';
import DeleteAccount from './pages/Delete_Account.js';
import Fallback from './pages/Fallback/Fallback.js';
import JoinUs from './pages/Join_Us.js';
import ScrollToTop from './pages/ScrollToTop.js';
import Follow_us from './pages/follow_us.js';
import Blog_listing from './pages/Blog_listing.js';


function App() {
	const location = useLocation();
	
	// Définir les routes où le Header et le Footer ne doivent pas s'afficher
	const noHeaderFooterRoutes = ['/pos-self/'];

	const shouldShowHeaderFooter = !noHeaderFooterRoutes.some(route => location.pathname.startsWith(route));

	return (
		<div className="App">
			{shouldShowHeaderFooter && <Header />}
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home_6 />} />
				<Route path="/about" element={<About />} />
				<Route path="/managementResto" element={<Features />} />
				<Route path="/follow_us" element={<Follow_us />} />
				<Route path="/blog_listing" element={<Blog_listing />} />
				<Route path="/pricing" element={<Pricing />} />

				<Route path="/reviews" element={<Reviews />} />

				<Route path="/single_post" element={<Single_post />} />

				<Route path="/privacy-policy" element={<Privacy />} />
				<Route path="/terms" element={<Terms />} />
			
				<Route path="/contact" element={<Contacts />} />
				<Route path="/join_us" element={<JoinUs />} />
				<Route path="/delete" element={<DeleteAccount />} />
				<Route path="/pos-self/:id/*" element={<Fallback />} />
				<Route path="/home_6" element={<Home_6 />} />
				
			</Routes>
			{shouldShowHeaderFooter && <Footer />}
		</div>
	);
}

export default App;

