import React, { Component } from 'react';
import ReactPlayer from 'react-player';


class Pricing extends React.Component {
  render() {
    return (
      <div>

        {/* PRELOADER SPINNER
    ============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
    ============================================= */}
        <div id="page" className="page">
          {/* HEADER
      ============================================= */}

          {/* ABOUT-US
      ============================================= */}
          <section id="about-us" className="bg-whitesmoke hero-offset-nav pb-50 about-us-section">
            <div className="container">
              {/* SECTION TITLE */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="mt-100 section-title text-center mb-70">
                    {/* Title */}
                    <h2 className="h2-md">Application de prise de commandes</h2>
                    {/* Text */}
                    <p className="p-xl">
                      Commandez depuis votre table en scannant un QR code et accédez au menu du restaurant directement via notre application intégrée à Odoo pour une gestion optimale.
                    </p>
                  </div>
                </div>
              </div>
              {/* VIDEO SECTION */}
              <div className="row">
                <div className="col-lg-10 offset-lg-1 text-center">
                  <div className="video-card">
                    <div className="video-wrapper">
                     {/* <ReactPlayer
                          url="https://www.youtube.com/watch?v=pctpdVurqRc"
                          className="react-player"
                          playing={false}
                          width="100%"
                          height="100%"
                          controls={true}
                      />*/}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* GEOMETRIC OVERLAY */}
            <div className="bg-fixed geometric-overlay" />
          </section>
          {/* ABOUT-2
      ============================================= */}
          <div id="about-2" className="bg_whitesmoke pb-80 about-section division">
            <div className="container">
              {/* TEXT BLOCK */}
              <div className="about-2-txt pc-50">
                {/* SMALL TITLE */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="txt-block text-center mb-40">
                      <h5 className="h5-xl">All of our pricing plans include:</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* LEFT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block top-box">
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod and pretium purus pretium ligula
                            rutrum tempor
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Aliqum mullam blandit tempor sapien gravida a donec ipsum, porta justo. Velna vitae
                            auctor a congue magna and donec nihil impedit ligula risus.mauris donec
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Quaerat sodales sapien an euismod purus blandit purus ipsum primis in cubilia laoreet
                            augue luctus dolor
                          </p>
                        </li>
                      </ul> {/* End List */}
                    </div>
                  </div>  {/* END LEFT COLUMN */}
                  {/* RIGHT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block">
                      {/* List */}
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Aliqum mullam blandit tempor sapien gravida a donec ipsum, porta justo. Velna vitae
                            auctor a congue magna and donec nihil impedit ligula risus.mauris donec
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod and pretium purus pretium ligula
                            rutrum tempor
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Quaerat sodales sapien an euismod purus blandit purus ipsum primis in cubilia laoreet
                            augue luctus dolor
                          </p>
                        </li>
                      </ul> {/* End List */}
                    </div>
                  </div>  {/* END RIGHT COLUMN */}
                </div>
              </div>  {/* END TEXT BLOCK */}
            </div>     {/* End container */}
          </div>  {/* END ABOUT-2 */}
          {/* STATISTIC-1
      ============================================= */}
          <div id="statistic-1" className="bg_whitesmoke pb-100 statistic-section division">
            <div className="container">
              {/* STATISTIC-1 WRAPPER */}
              <div className="statistic-1-wrapper text-center">
                <div className="row">
                  {/* STATISTIC BLOCK #1 */}
                  <div id="sb-1-1" className="col-sm-6 col-lg-3">
                    <div className="statistic-block wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="statistic-1-ico ico-70 grey-color"><span className="flaticon-mobile-phone" /></div>
                      {/* Digit */}
                      <h2 className="h2-xl statistic-number"><span className="count-element">28</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">Faster Access</h5>
                    </div>
                  </div>
                  {/* STATISTIC BLOCK #2 */}
                  <div id="sb-1-2" className="col-sm-6 col-lg-3">
                    <div className="statistic-block wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="statistic-1-ico ico-70 grey-color"><span className="flaticon-analytics-1" /></div>
                      {/* Digit */}
                      <h2 className="h2-xl statistic-number"><span className="count-element">47</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">App Productivity</h5>
                    </div>
                  </div>
                  {/* STATISTIC BLOCK #3 */}
                  <div id="sb-1-3" className="col-sm-6 col-lg-3">
                    <div className="statistic-block wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="statistic-1-ico ico-70 grey-color"><span className="flaticon-padlock" /></div>
                      {/* Digit */}
                      <h2 className="h2-xl statistic-number"><span className="count-element">54</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">Secure Access</h5>
                    </div>
                  </div>
                  {/* STATISTIC BLOCK #4 */}
                  <div id="sb-1-4" className="col-sm-6 col-lg-3">
                    <div className="statistic-block wow fadeInUp" data-wow-delay="1s">
                      {/* Icon */}
                      <div className="statistic-1-ico ico-70 grey-color"><span className="flaticon-phone-2" /></div>
                      {/* Digit */}
                      <h2 className="h2-xl statistic-number"><span className="count-element">36</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">Battery Saving</h5>
                    </div>
                  </div>
                </div>  {/* End row */}
              </div>  {/* END STATISTIC-1 WRAPPER */}
            </div>   {/* End container */}
          </div>   {/* END STATISTIC-1 */}
          {/* FAQs-2
      ============================================= */}

          {/* DOWNLOAD-6
      ============================================= */}
          <section id="download-6" className="bg_mobile wide-100 download-section division">
            <div className="container white-color">
              <div className="row">
                {/* DOWNLOAD TXT */}
                <div className="col-lg-6">
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}
                    <span className="section-id">Powerful Settings</span>
                    {/* Title   */}
                    <h2 className="h2-md">Take Full Advantage of Smart Features</h2>
                    {/* Text */}
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit
                      posuere orci auctor purus euismod an aliquam quaerat purus
                    </p>
                    {/* STORE BADGES */}
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a>
                    
                    </div>  {/* END STORE BADGES */}
                  </div>
                </div>  {/* END DOWNLOAD TXT */}
              </div>    {/* End row */}
            </div>     {/* End container */}
          </section>  {/* END  DOWNLOAD-6 */}
          {/* FOOTER-2
      ============================================= */}
        
        </div>  {/* END PAGE CONTENT */}
      </div>


    )
  }
}
export default Pricing