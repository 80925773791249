import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';

import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <BrowserRouter basename='/'>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// Pour mesurer les performances de l'application
reportWebVitals();
