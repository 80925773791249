import UAParser from 'ua-parser-js';
const appInstalledUserAgents = [
  'com.menupro.app',
  'com.example.menupro',
];
export const handleDeepLinkRedirection = (deepLinkUrl) => {
  const userAgent = window.navigator.userAgent;
  const parser = new UAParser(userAgent);
  const isMobileDevice = parser.getDevice().type === 'mobile';
  let isWeb;
  if (isMobileDevice) {
    isWeb=false;
    const isAppInstalled = appInstalledUserAgents.some((appUserAgent) =>
      userAgent.includes(appUserAgent)
    );
    if (isAppInstalled) {
      window.location.href = deepLinkUrl;
    } else {
      const osName = parser.getOS().name;
      let appStoreUrl;
      if (osName === 'Android') {
        appStoreUrl = 'https://play.google.com/store/apps/details?id=com.menupro.app';
      } else if (osName === 'iOS') {
        appStoreUrl = 'https://apps.apple.com/tn/app/menupro/id6464469617';
      } else {
        console.log('This link is intended for mobile devices.');
      }
      window.location.href = appStoreUrl;
    }
  } else {
    isWeb=true;
    console.log('This link is intended for mobile devices.');
    return isWeb;
  }
};






