import React from 'react';
import { Link } from 'react-router-dom';

class Privacy extends React.Component {
  render() {
    return (
      <div>


        {/* PRELOADER SPINNER
		============================================= */}
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}
        <div id="page" className="page">

          {/* TERMS & PRIVACY
			============================================= */}
          <section id="terms-page" className="bg_whitesmoke hero-offset-nav pb-70 terms-section division">
            <div className="mt-50 container">
              <div className="row">
                {/* TERMS CONTENT */}
                <div className="col-lg-10 offset-lg-1">
                  {/* TERMS TITLE */}
                  <div className="terms-title text-center">
                    {/* Title */}
                    <h2 className="h2-md">MenuPro Privacy Policy</h2>
                    {/* Text */}
                    <p className="p-xl grey-color"></p>
                  </div>	{/* END TERMS TITLE */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Text */}
                    <p className="p-lg">MenuPro understands that the services provided to you or performed on your behalf involve a deal of trust on your part. We take this trust very seriously and make it our highest priority to ensure the security and confidentiality of the Personal Data provided to us. Please read the following policy to learn about our privacy practices. By using our MenuPro App, Web site (the "Site") or benefiting from the services available from the Site, you are accepting the practices described herein. MenuPro provides this Privacy Policy to describe our procedures regarding the collection, use, and disclosure of personal data we collect or receive from users and Web visitors.
                    </p>

                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Definitions</h4>
                    {/* Text */}
                    <p className="p-lg">Personal data "(Personal Data") within this policy refers to any information relating to an identified or identifiable natural person in the meaning of:
                    </p>
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> for data subjects residing in Tunisia, Organic Law No. 2004-63 of July 27, 2004, relating to the protection of personal data

                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> for data subjects residing in the EEA territory, the Regulation (EU) 2016/679 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data
                        </p>
                      </li>
                    </ul>

                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Collection and Use of Personal Data</h4>
                    <h6 className="h4-sm">Purpose</h6>
                    {/* Text */}
                    <p className="p-lg">
                      The main reason why we collect Personal Data about you is to provide you the services and improve the Site features and content, to administer your user account, and to enable you to enjoy and easily navigate the App , Site and using the services. When you register through the App to become a user, when you create or edit your user account and use a service, we will ask you for Personal Data. We may collect the Personal Data that you provide as part of registration and the administration and personalization of your user account, or Personal Data that you provide us by using the services.
                    </p>
                    <h6 className="h4-sm">Extend</h6>
                    <p className="p-lg">
                      MenuPro may collect the following Personal Data:
                    </p>
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> When you register through the App to become a user, when you create or edit your user account and use a service, we will ask you for Personal Data. We may collect the Personal Data that you provide as part of registration and the administration and personalization of your user account, or Personal Data that you provide us by using the services.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> Contact information, such as name, physical address, telephone number(s), email address, and company.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> Transactional information, including information about the products and services you purchase or download or about which you make an inquiry.
                        </p>
                      </li>
                    </ul>
                    <p className="p-lg">
                      We may supplement the information you provide with information received from third parties.
                    </p>
                    <h6 className="h4-sm">Data Use</h6>
                    <p className="p-lg">
                      MenuPro uses Personal Data for the purpose of offering the services. The data transmitted to MenuPro by you may be hosted by MenuPro or a third party of its choice, as part of the services. We use your Personal Data for the following general purposes:
                    </p>
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to fulfill your requests for services.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to improve the services.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to conduct research and compile statistics on usage patterns.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to manage your user account; to communicate with you in general.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to notify you about MenuPro promotional offers and services that may be of interest to you.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to prevent potentially prohibited or illegal activities; to enforce the Terms of Use of the Site; and as otherwise described to you at the point of collection.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to comply with legal requirements.</p>
                      </li>
                    </ul>
                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX #3 */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Sharing Personal Data
                    </h4>
                    {/* Text */}
                    <p className="p-lg">We may share aggregated information that does not include Personal Data and we may otherwise disclose non-identifying information with third parties for analysis and other purposes. Any aggregated information shared in these contexts will not contain Personal Data. Under certain circumstances, MenuPro may be required to disclose Personal Data in response to valid requests by public authorities, including to meet national security or law enforcement requirements. MenuPro may disclose Personal Data if required to do so by law in order to (for example) respond to a subpoena or request from law enforcement, a court or a government agency, or in the good faith belief that such action is necessary:
                    </p>
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to comply with a legal obligation.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to protect or defend our rights, interests or property or that of third parties.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to prevent or investigate possible wrongdoing in connection with the App, Site or our services.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to act in urgent circumstances to protect the personal safety of users of the App, Site, our services or the public.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500"></span> to protect against legal liability.</p>
                      </li>
                    </ul>
                    <p className="p-lg">Where it relates to Personal Data, we will attempt to refer any request for disclosure of personal information by public authorities, including those received for national security or law enforcement reasons, to the Data Subjects. MenuPro may, where legally obligated to do so, disclose Personal Data to law enforcement or other government authorities, in which case MenuPro will notify its Data Subjects of such a request unless prohibited by law to do so.
                    </p>
                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Data Subject Rights</h4>
                    {/* Text */}
                    <p className="p-lg">You have certain rights with respect to your Personal Data, including those set forth below. For more information about these rights, or to submit a request, please email us info@MenuPro.tn. Please note that in some circumstances, we may not be able to fully comply with your request, such as if it is frivolous or extremely impractical, if it jeopardizes the rights of others, or if it is not required by law, but in those circumstances, we will still respond to notify you of such a decision. In some cases, we may also need you to provide us with additional information, which may include Personal Data, if necessary to verify your identity and the nature of your request:
                    </p>
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Access</span> You can request more information about the Personal Data we hold about you and request a copy of such Personal Data. You can also access certain of your Personal Data by logging on to your account.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Rectification</span> If you believe that any Personal Data we are holding about you is incorrect or incomplete, you can request that we correct or supplement such data. You can also correct some of this information directly by logging on to your account.
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Erasure</span> You can request that we erase some or all of your Personal Data from our systems.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Withdrawal of Consent</span> If we are processing your Personal Data based on your consent (as indicated at the time of collection of such data), you have the right to withdraw your consent at any time. Please note, however, that if you exercise this right, you may have to then provide express consent on a case-by-case basis for the use or disclosure of certain of your Personal Data, if such use or disclosure is necessary to enable you to utilize some or all of our Services.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Portability</span> You can ask for a copy of your Personal Data in a machine-readable format. You can also request that we transmit the data to another controller where technically feasible.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Objection</span> You can contact us to let us know that you object to the further use or disclosure of your Personal Data for certain purposes, such as for direct marketing purposes.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Restriction of Processing</span> You can ask us to restrict further processing of your Personal Data.</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Right to File Complaint</span> You have the right to lodge a complaint about MenuPro`s practices with respect to your Personal Data with the supervisory authority of your country.</p>
                      </li>
                    </ul>
                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX #5 */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Transfers of Personal Data</h4>
                    {/* Text */}
                    <p className="p-lg">The Services are hosted and operated in Tunisia through MenuPro and its service providers. By using the Services, you acknowledge that any Personal Data about you, regardless of whether provided by you or obtained from a third party, is being provided to MenuPro in Tunisia and will be hosted on Tunisian servers, and you authorize MenuPro to transfer, store and process your information to and in Tunisia. You hereby consent to the transfer of your data to Tunisia pursuant to (i) binding corporate rules for data protection that align with Tunisia, Organic Law and GDPR’s requirements, or (ii) adherence to an industry- or technology-specific approved code of conduct blessed by the Tunisian authorities.</p>

                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Changes to this Privacy Policy</h4>
                    {/* Text */}
                    <p className="p-lg">We`re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we will alert you to any such changes by placing a notice on MenuPro website, by sending you an email and/or by some other means. Please note that if you`ve opted not to receive legal notice emails from us (or you haven`t provided us with your email address), those legal notices will still govern your use of the Services, and you are still responsible for reading and understanding them. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.
                    </p>
                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Contact Information</h4>
                    {/* Text */}
                    <p className="p-lg">If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:
                    </p>
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Our website is</span> <a href="https://menupro.tn/" target="_blank" rel="noopener noreferrer">menupro.tn</a>
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg"><span className="txt-500">Email us at</span> info@MenuPro.tn
                        </p>
                      </li>
                    </ul>
                  </div>	{/* END TERMS BOX */}

                </div>	{/* END TERMS CONTENT */}
              </div>     {/* End row */}
            </div>	    {/* End container */}
            {/* GEOMETRIC OVERLAY */}
            <div className="bg_fixed geometric_overlay" />
          </section>	 {/* END TERMS & PRIVACY */}
          {/* NEWSLETTER-1
			============================================= */}
          <section id="newsletter-1" className="pb-20 newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg_white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}
                  <div className="col-lg-6">
                    <div className="newsletter-txt">
                      {/* Section ID */}
                      <span className="section-id">Rejoignez-nous</span>
                      {/* Title */}
                      <h4 className="h4-xl">Intégrez une famille dynamique et passionnée</h4>
                    </div>
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">

                        <span className="ml-300 input-group-btn">
                          <Link to="/join_Us">
                            <button type="submit" className="btn btn-md btn-skyblue tra-skyblue-hover">Rejoinez nous</button>
                          </Link>
                        </span>
                      </div>
                      {/* Newsletter Form Notification */}
                      <label htmlFor="s-email" className="form-notification" />
                    </form>
                  </div>	  {/* END NEWSLETTER FORM */}
                </div>	  {/* End row */}
              </div>    {/* End newsletter-holder */}
            </div>	   {/* End container */}
          </section>	{/* END NEWSLETTER-1 */}

        </div>	{/* END PAGE CONTENT */}



      </div>
    )
  }
}
export default Privacy 